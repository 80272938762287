import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { CButton, CCallout, CAlert } from '@coreui/react';
import DOMPurify from 'dompurify';
import axios from 'axios';
import FriendlyHelperDropdown from './FriendlyHelperDropdown';
import {
  getAPIs,
  getApplication,
  postAadRequest,
  createNewConsumer
} from '../../api/friendlyHelper';
import { APIInfo, Application } from '../../types';
import './friendlyhelper.scss';
import { InitAadRequest } from './aadRequestType';
import RequestsTable from './RequestsTable';
import NewConsumerForm from './NewConsumerForm';
import { logger } from '../../logger';

const formatSubmitAadRequest = (
  aadRequest: InitAadRequest,
): Record<string, any> => {
  return {
    consumer: {
      app_id: aadRequest.application.app_id,
      name: aadRequest.application.display_name,
      type: aadRequest.application.type,
      owner: aadRequest.application.owner,
    },
    api: {
      app_id: aadRequest.registration.app_id,
      name: aadRequest.registration.aad_application !== null ? aadRequest.registration.aad_application?.display_name : "NB! No aad application found",
      owner: {
        name: `${aadRequest.api.owner.first_name} ${aadRequest.api.owner.last_name}`,
        email: aadRequest.api.owner.email,
        id: aadRequest.api.owner.aad_object_id,
      },
      data_classification: aadRequest.api.data_classification !== null ? aadRequest.api.data_classification : "No data classification available."
    },
    permissions: Array.from(
      aadRequest.roles.map((role: any) => ({
        value: role.value,
        id: 'role_id' in role ? role.role_id : role.scope_id,
      })),
    ),
  };
};

function FriendlyHelperHomePage(): JSX.Element {
  const { instance, accounts } = useMsal();
  const [applicationList, setApplicationList] = useState<Application[]>([]);
  const [apiList, setApiList] = useState<APIInfo[]>([]);
  const [aadRequest, setAadRequest] = useState<InitAadRequest | any>();
  const [aadRequestList, setAadRequestList] = useState<Array<InitAadRequest>>(
    [],
  );

  const [aadResponse, setAadResponse] = useState<string>('');
  const [submitStatus, setSubmitStatus] = useState<boolean>(false);
  const [enableWarning, setEnableWarning] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>();
  const [employeeAPIWarning, setEmployeeAPIWarning] = useState<string>();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successMessageVisibility, setSuccessMessageVisibility] = useState(false);
  const [newConsumer, setNewConsumer] = useState('');

  const fetchHelperData = async (): Promise<void> => {
    try {
      const applications = await getApplication(instance, accounts);
      const apis = await getAPIs(instance, accounts);
      setApplicationList(applications);
      setApiList(apis);
    } catch (error) {
      setErrorMessageVisibility(true);
      setErrorMessage('Unexpected error');
      if (axios.isAxiosError(error)) {
        // Axios error
        if (error.response) {
          logger.error('Axios Error Message:', error.message);
          logger.error('Axios Error Detail:', error.response.statusText);
        }
      } else {
        // Any other error
        logger.error('Unexpected error while fetching the helper data:', error);
      }
    }
  };

  const generateAadRequestList = (): void => {
    const similarRequest = aadRequestList.find(
      (requestItem) =>
        Object.entries(requestItem.application).toString() ===
          Object.entries(aadRequest.application).toString() &&
        Object.entries(requestItem.api).toString() ===
          Object.entries(aadRequest.api).toString() &&
        Object.entries(requestItem.registration).toString() ===
          Object.entries(aadRequest.registration).toString(),
    );
    if (!similarRequest) {
      const newAadRequestList = [...aadRequestList, aadRequest];
      setAadRequestList(newAadRequestList);
      setAadRequest(undefined);
      setSubmitStatus(true);
    } else if (
      aadRequest.roles.filter(
        (newPermission: any) => !similarRequest.roles.includes(newPermission),
      ).length > 0 ||
      similarRequest.roles.filter(
        (existPermission: any) => !aadRequest.roles.includes(existPermission),
      ).length > 0
    ) {
      const index = aadRequestList.indexOf(similarRequest);
      const newAadRequestList = [...aadRequestList];
      newAadRequestList[index] = aadRequest;
      setAadRequestList(newAadRequestList);
      setAadRequest(undefined);
      setEnableWarning(true);
      setWarningMessage(
        'You have already made a permission request with the same consuming application, api, and the environment. We replace that with your new one.',
      );
    } else {
      setEnableWarning(true);
      setWarningMessage(
        'Warning: This permission request has already been made!',
      );
    }
  };

  const sendAadRequest = async (): Promise<void> => {
    const submitAadRequestList = Array.from(
      aadRequestList.map((request) => formatSubmitAadRequest(request)),
    );
    try {
      const response = await postAadRequest(
        instance,
        accounts,
        submitAadRequestList,
      );
      setAadResponse(response.request_text);
      setAadRequestList([]);
      setEnableWarning(false);
      setEmployeeAPIWarning(undefined);
      setWarningMessage(undefined);
    } catch (error) {
      setErrorMessageVisibility(true);
      setErrorMessage('Unexpected error');
      if (axios.isAxiosError(error)) {
        // Axios error
        if (error.response) {
          logger.error('Axios Error Message:', error.message);
          logger.error('Axios Error Detail:', error.response.statusText);
        }
      } else {
        // Any other error
        logger.error('Unexpected error while sending AAD request:', error);
      }
    }
  };

  const removeAadRequest = (request: InitAadRequest): void => {
    const changedAadRequestList = aadRequestList.filter(
      (requestItem) => requestItem !== request,
    );
    setAadRequestList(changedAadRequestList);
  };

const handleNewConsumer = (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  const { value } = event.target;
  setNewConsumer(value);
};

 const addNewConsumer = async (): Promise<void> => {
  try {
    const bodyContent: any = { display_name: newConsumer };
    const data = await createNewConsumer(instance, accounts, bodyContent);
    if (data !== '') {
      setSuccessMessageVisibility(true)
      logger.log('Consumer added successfully');
      setSuccessMessage('New consumer added successfully! Please refresh the page.')
  }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios Error
      if (error.response) {
        // An example of error.message: Request failed with status code 404
        logger.log('Axios Error Message:', error.message);
        logger.log('Axios Error Detail:', error.response.statusText);
        setErrorMessageVisibility(true);
        try {
        setErrorMessage(error.response.data.display_name[0])
        } catch {
          logger.log('Unexpected error while adding the new consumer', error);
          setErrorMessage('Unexpected error while adding the new consumer')
        }
      } else {
        logger.log('Unexpected error while adding the new consumer', error);
        setErrorMessage('Unexpected error while adding the new consumer')
      }
    } else {
      // Any other error
      logger.log('Unexpected error while adding the new consumer', error);
      setErrorMessage('Unexpected error while adding the new consumer')
    }
  }
};

  useEffect(() => {
    fetchHelperData();
  }, []);

  return (
    <div className="page-body">
      <CAlert
        color="danger"
        variant="solid"
        dismissible
        visible={errorMessageVisibility}
        onClose={() => setErrorMessageVisibility(false)}>
        {errorMessage}
      </CAlert>

      <CAlert
        color="success"
        variant="solid"
        dismissible
        visible={successMessageVisibility}
        onClose={() => setSuccessMessageVisibility(false)}>
        {successMessage}
      </CAlert>

      <h1>Azure AD Federation Request Helper</h1>
      <p className="fs-5">
        This form helps to formulate requests for adding API permissions for
        existing applications in Azure Active Directory, see{' '}
        <a
          href="https://confluence.devops.wartsila.com/display/APIM/Azure+AD+Federation+Request+helper"
          target="blank">
          Azure AD Federation Request helper
        </a>{' '}
        for more information.
      </p>
      <div className="friendly-helper-form">
        <FriendlyHelperDropdown
          applications={applicationList}
          apis={apiList}
          setAadRequest={setAadRequest}
          generateAadRequestList={generateAadRequestList}
          submitStatus={submitStatus}
          setSubmitStatus={setSubmitStatus}
          setEnableWarning={setEnableWarning}
          setWarningMessage={setWarningMessage}
          setEmployeeAPIWarning={setEmployeeAPIWarning}
        />
        {enableWarning && employeeAPIWarning && (
          <CCallout color="warning" className="fw-semibold text-warning">
            <p
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(employeeAPIWarning),
              }}
              className="mb-0"
            />
          </CCallout>
        )}
        {enableWarning && warningMessage && (
          <CCallout color="warning" className="fw-semibold text-warning">
            {warningMessage}
          </CCallout>
        )}
      <NewConsumerForm
      handleNewConsumer={handleNewConsumer}
      addNewConsumer={addNewConsumer}
      newConsumer={newConsumer}
      />
        <RequestsTable
          aadRequestList={aadRequestList}
          removeAadRequest={removeAadRequest}
        />
        <div className="d-flex">
          <CButton
            color="info"
            onClick={sendAadRequest}
            disabled={aadRequestList.length === 0}>
            Formulate request
          </CButton>
        </div>
        {aadResponse.length > 0 && (
          <div className="d-flex flex-column mt-3">
            <p className="fs-5">
              Almost there!
              <br />
              Please go to{' '}
              <a
                href="https://wartsila.service-now.com/im?id=sc_cat_item&sys_id=722ee35edb9c470088a2f3b31d961925"
                target="blank">
                Azure AD Federation Request
              </a>{' '}
              and paste the following text as “Request Description”:
              <br />
              (Select the matching entry from the application portfolio or API
              Developer Portal)
            </p>
            <div className="text-bg-light response">{aadResponse}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FriendlyHelperHomePage;
