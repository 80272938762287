import { IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { APIInfo, Application } from '../types';
import { sendHttpRequest } from './general';

export const getApplication = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
): Promise<Array<Application>> => {
  const response = await sendHttpRequest(
    instance,
    accounts,
    'consumers/?fields=app_id,type,display_name,owner',
    'GET',
  );
  return response?.results;
};

export const getAPIs = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
): Promise<Array<APIInfo>> => {
  const response = await sendHttpRequest(
    instance,
    accounts,
    'apis/?fields=id,display_name,app_registrations,owner,is_deployed_to_dev,is_deployed_to_qa,is_deployed_to_prod,status,data_classification&authentication_method=oauth2',
    'GET',
  );
  return response?.results;
};

export const createNewConsumer = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  bodyContent: any,
): Promise<any> => {
  const response = await sendHttpRequest(
    instance,
    accounts,
    'consumers/',
    'POST',
    bodyContent
  );
  return response?.display_name;
};


// currently this API hasn't been deployed to QA and Prod, so I used this way as the temporary one.
export const postAadRequest = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  bodyContent: Record<string, any>,
): Promise<any> => {
  const response = await sendHttpRequest(
    instance,
    accounts,
    'aad-federation-request/assign-permission-to-app/',
    'POST',
    bodyContent,
  );
  return response;
};
