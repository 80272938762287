import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormLabel,
  CInputGroup,
} from '@coreui/react';

import { ChangeEvent, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../..';
import { APIInfo, Application } from '../../types';
import { AppRegistration, Permission } from './aadRequestType';
import PermissionSelection from './PermissionSelection';
import OptionList from './OptionList';
import { initConfig } from '../../api/general';
import { APPLICATION } from '../../utils/constants';

function FriendlyHelperDropdown({
  applications,
  apis,
  setAadRequest,
  generateAadRequestList,
  submitStatus,
  setSubmitStatus,
  setEnableWarning,
  setEmployeeAPIWarning,
  setWarningMessage,
}: {
  applications: Application[];
  apis: APIInfo[];
  setAadRequest: any;
  generateAadRequestList: () => void;
  submitStatus: boolean;
  setSubmitStatus: any;
  setEnableWarning: any;
  setEmployeeAPIWarning: any;
  setWarningMessage: any;
}): JSX.Element {
  const MFILESCLOUD = 'M-Files Cloud (OAuth)';
  const DEV = 'dev';
  const QA = 'qa';
  const PROD = 'prod';

  const [applicationVisible, setApplicationVisible] = useState<boolean>(false);
  const [apiVisible, setApiVisible] = useState<boolean>(false);

  const { account } = useContext(AuthContext);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  const [filterOption, setFilterOption] = useState({
    application: '',
    api: '',
    environment: '',
  });
  const [selectedAPI, setSelectedAPI] = useState<APIInfo>();
  const [selectedApplication, setSelectedApplication] = useState<Application>();
  const [registration, setRegistration] = useState<AppRegistration>();
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    [],
  );

  const [filteredApplications, setFilteredApplications] = useState<
    Application[]
  >([]);
  const [filteredApis, setFilteredApis] = useState<APIInfo[]>([]);
  const [environmentAvailable, setEnvironmentAvailable] = useState<string[]>(
    [],
  );
  const [permissionsAvailable, setPermissionsAvailable] = useState<
    Permission[]
  >([]);

  const updateEnvironmentList = (apiOption: APIInfo): void => {
    const envList: string[] = [];
    if (apiOption.is_deployed_to_dev) {
      envList.push('Dev');
    }
    if (apiOption.is_deployed_to_qa) {
      envList.push('QA');
    }
    if (apiOption.is_deployed_to_prod) {
      envList.push('Prod');
    }
    setEnvironmentAvailable(envList);
  };

  const updateRoleList = (envOption: string): void => {
    const fetchRegistration = selectedAPI?.app_registrations.find(
      (appRegistration) => appRegistration.environment === envOption,
    );
    if (fetchRegistration) {
      setRegistration(fetchRegistration);
      if (selectedApplication && selectedApplication.type !== 'sp') {
        setPermissionsAvailable(
          fetchRegistration.aad_application?.appscopes.sort(
            (prevRole, currRole) =>
              prevRole.value.toUpperCase() < currRole.value.toUpperCase()
                ? -1
                : 1,
          ) ?? [],
        );
      } else {
        const filterRoleList =
          fetchRegistration.aad_application?.approles.filter(
            (role) =>
              role.allowed_member_types.includes(APPLICATION) &&
              role.is_enabled,
          );
        setPermissionsAvailable(
          filterRoleList?.sort((prevRole, currRole) =>
            prevRole.value.toUpperCase() < currRole.value.toUpperCase()
              ? -1
              : 1,
          ) ?? [],
        );
      }
    }
  };

  const handleClickSelection = (key: string, option: any): void => {
    setEnableWarning(false);
    setEmployeeAPIWarning();
    setWarningMessage();
    switch (key) {
      case 'application':
        setFilterOption({
          ...filterOption,
          application: option.display_name,
        });
        setApplicationVisible(false);
        setSelectedApplication(option);
        break;
      case 'api':
        setFilterOption({
          ...filterOption,
          api: option.display_name,
        });
        setApiVisible(false);
        setSelectedAPI(option);
        if (
          option.id === initConfig.employeeID &&
          account.username !== option.owner.email
        ) {
          setEnableWarning(true);
          setEmployeeAPIWarning(
            `NB. Not applicable to Employee API because of the data sensitivity. Please align with ${option.owner.first_name} ${option.owner.last_name} first and fill in <a href="https://forms.office.com/r/hMH4jWxZhN" target="blank">this form</a>`,
          );
        } else {
          updateEnvironmentList(option);
        }
        break;
      case 'environment':
        setFilterOption({
          ...filterOption,
          environment: option,
        });
        break;
      case 'permission':
        setSelectedPermissions(option);
        break;
      default:
        break;
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    // when API selection changes, the env list must be empty.
    if (event.target.name === 'api' && filterOption.api.length > 0) {
      setEnvironmentAvailable([]);
    }
    setFilterOption({
      ...filterOption,
      [event.target.name]: event.target.value,
    });
    const containsDataStatus = event.target.value.length > 0;
    if (event.target.name === 'application') {
      setApplicationVisible(containsDataStatus);
    } else {
      setApiVisible(containsDataStatus);
    }
  };

  useEffect(() => {
    if (submitStatus) {
      setDisabledButton(true);
      setSubmitStatus(false);
    }
  }, [submitStatus]);

  useEffect(() => {
    const specialCase = apis.find((api) => api.display_name === MFILESCLOUD);
    specialCase?.app_registrations.forEach((appReg) => {
    if (appReg.aad_application !== null) {
      const apiInfo: APIInfo = {
        id: specialCase.id,
        app_registrations: [appReg],
        display_name: appReg.aad_application?.display_name,
        is_deployed_to_dev: appReg.environment === DEV,
        is_deployed_to_qa: appReg.environment === QA,
        is_deployed_to_prod: appReg.environment === PROD,
        owner: specialCase.owner,
        approvers: specialCase.approvers,
        maintainers: specialCase.maintainers,
      };
      apis.push(apiInfo);
    }});
    setFilteredApplications(applications);
    setFilteredApis(apis);
  }, [applications, apis]);

  useEffect(() => {
    if (filterOption.application.length > 0) {
      const newApplicationsList = applications.filter((option) =>
        option.display_name
          .toLowerCase()
          .includes(filterOption.application.toLowerCase()),
      );
      const sortedApplicationList = newApplicationsList.sort(
        (prevApp, currApp) =>
          prevApp.display_name.toUpperCase() <
          currApp.display_name.toUpperCase()
            ? -1
            : 1,
      );
      setFilteredApplications(sortedApplicationList);
    } else {
      setFilteredApplications(applications);
    }
  }, [filterOption.application]);

  useEffect(() => {
    setFilterOption({
      ...filterOption,
      environment: '',
    });
    setSelectedPermissions([]);
    if (filterOption.api.length > 0) {
      const newApisList = apis.filter(
        (option) =>
          option.display_name
            .toLowerCase()
            .includes(filterOption.api.toLowerCase()) &&
          option.status !== 'deprecated',
      );
      const sortedApisList = newApisList.sort((prevApi, currApi) =>
        prevApi.display_name.toUpperCase() < currApi.display_name.toUpperCase()
          ? -1
          : 1,
      );
      setFilteredApis(sortedApisList);
    } else {
      setFilteredApis(apis);
    }
  }, [filterOption.api]);

  useEffect(() => {
    setSelectedPermissions([]);
    if (selectedApplication && selectedAPI && filterOption.environment !== '') {
      updateRoleList(filterOption.environment.toLowerCase());
    }
  }, [filterOption]);

  useEffect(() => {
    if (
      selectedApplication &&
      registration &&
      selectedAPI &&
      selectedPermissions.length > 0
    ) {
      setAadRequest({
        application: selectedApplication,
        registration,
        api: selectedAPI,
        roles: selectedPermissions,
      });
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [selectedApplication, registration, selectedAPI, selectedPermissions]);

  return (
    <div className="form-container">
      <div className="friendly-helper-dropdown application">
        <div className="friendly-helper-input">
          <CFormLabel>Select the consuming application</CFormLabel>
          <CFormInput
            floatingLabel="Application"
            placeholder="Application"
            name="application"
            onChange={handleChange}
            value={filterOption.application}
            autoComplete="off"
          />
        </div>
        {applicationVisible && (
          <OptionList
            setOptionsVisible={setApplicationVisible}
            listOfOptions={filteredApplications}
            handleChange={handleClickSelection}
            type="application"
          />
        )}
      </div>
      <div className="friendly-helper-dropdown api">
        <div className="friendly-helper-input">
          <CFormLabel>
            Select the API and the{' '}
            {selectedApplication && selectedApplication.type !== 'sp'
              ? 'scopes'
              : 'roles'}{' '}
            to be used
          </CFormLabel>
          <CInputGroup>
            <CFormInput
              floatingLabel="API"
              placeholder="API"
              onChange={handleChange}
              value={filterOption.api}
              name="api"
              autoComplete="off"
            />
            <CDropdown variant="input-group">
              <CDropdownToggle color="secondary" variant="outline">
                {filterOption.environment !== ''
                  ? filterOption.environment
                  : 'Environment'}
              </CDropdownToggle>
              <CDropdownMenu>
                {environmentAvailable.length > 0 ? (
                  environmentAvailable.map((env) => (
                    <CDropdownItem
                      key={env}
                      as="button"
                      onClick={() => handleClickSelection('environment', env)}>
                      {env}
                    </CDropdownItem>
                  ))
                ) : (
                  <p className="p-2 m-0 text-body-secondary">
                    No environment available
                  </p>
                )}
              </CDropdownMenu>
            </CDropdown>
            <PermissionSelection
              permissionList={permissionsAvailable}
              permissionClickHandler={handleClickSelection}
              displayPermissionList={
                filterOption.environment !== '' &&
                permissionsAvailable.length > 0
              }
              refreshPermissionList={selectedPermissions.length === 0}
              dropdownTitle={
                selectedApplication && selectedApplication.type !== 'sp'
                  ? 'Scope'
                  : 'Role'
              }
            />
          </CInputGroup>
        </div>
        {apiVisible && (
          <OptionList
            setOptionsVisible={setApiVisible}
            listOfOptions={filteredApis}
            handleChange={handleClickSelection}
            type="api"
          />
        )}
      </div>
      <CButton
        color="info"
        onClick={() => generateAadRequestList()}
        disabled={disabledButton}
        className="add-to-list-button">
        Add to list
      </CButton>
    </div>
  );
}

export default FriendlyHelperDropdown;
